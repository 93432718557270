.login-container {
    display: grid;
    grid-row-start: 2;
    grid-row-end: 5;
    .login-content {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        width: 600px;
		padding: 2rem;
		max-width: 100%;		
        margin: 0 auto;
        text-align: center;
        position: relative;
        bottom: 40px;
        .login-actions {
            margin: 30px 0px 0px 0px;
            button {
                margin: 0px 10px;
            }
        }
    }
}

@media only screen and (max-width: 920px) {
	.login-container {
		.login-content {
			width: auto;
		}
	}

}