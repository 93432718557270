.box-reveal-item-container {
    margin: auto;
    cursor: pointer;
    position: relative;
    .reveal-video-container {
        width: 300px;
        height: 300px;
        transition: 250ms;
        overflow: hidden;
        border: 2px solid #efefef;
        video[poster] {
            transition: 250ms;
        }
    }
    .revel-item-box-id {
        text-align: center;
        line-height: 42px;
    }
    &.active {
        .reveal-video-container {
            border: 2px solid #333;
        }
    }
    &:not(.active):hover {
        .reveal-video-container {
            border: 2px solid #efefef;
        }
    }

}
@media only screen and (max-width: 1200px) {
	.box-reveal-item-container {
		.reveal-video-container {
		    width: 100%;
			height: auto;
		}
	}
}