.approval-container {
    display: flex;
	flex-direction: column;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-template-rows: 80px calc(100vh - 140px);
    .approval-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        width: 30rem;
        margin: 2rem auto;
		padding-bottom: 2rem;
        position: relative;
        bottom: 10px;
        .loader-video-container {
            width: 270px;
            height: 270px;
        }
        h1 {
            margin: 0px 0px 30px 0px;
            font-weight: bold;
            font-size: 40px;
        }
        p {
            margin: 0px 0px;
        }
        .error-container {
            margin: 60px 0px;
            display: flex;
            align-items: center;
            .error-icon {
                font-size: 35px;
                color: red;
                margin: 0px 15px 0px 0px;
            }
        }
        .error-actions-container {
            button {
                margin: 0px 15px;
            }
        }
        .not-refresh {
            font-style: italic;
            margin: 30px 0px;
        }
		.approval-buttons{
			flex-direction: row;
			button{
			   margin: 0 0.6rem 0 0.6rem;
			}
		
		}
        .transaction-hash-content {
            font-size: 12px;
            margin: 0px 0px 30px 0px;
            .transaction-address-link {
                color: black;
                .transaction-address {
                    text-decoration: underline;
                    margin: 0px 5px;
                }
            }
        }
    }
}

@media only screen and (max-width: 900px) {
	.reveal-items-container {
        padding: 2rem;
        &.no-items {
            .reveal-items-actions {
                margin: 0px 0px 30px 0px;
            }
        }
    }
}

@media (min-width: 901px) and (max-width: 1200px) {

}

@media (min-width: 1201px) and (max-width: 1600px) {

}
@media only screen and (max-width: 600px) {
	.approval-container {
		.approval-content {
			width: auto;
			padding: 2rem;
			.ant-btn-primary {
				width: 140px;
			}
		
		}
	}
}  