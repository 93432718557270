.revealed-item-container {
    display: grid;
    overflow: hidden;
    box-sizing: border-box;
    height: 100%;
    grid-row-start: 2;
    grid-row-end: 3;
    padding: 70px;
    .revealed-video-container {
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        border: 1px solid #efefef;
        &.active {
            video {
                display: inherit;
            }
            .loader-icon {
                display: none;
            }
        }
        .close-icon {
            position: absolute;
            top: 15px;
            right: 15px;
            font-size: 1.5rem;
            cursor: pointer;
            z-index: 10;
        }
        .loader-icon {
            font-size: 5rem;
        }
        .no-items-video-container .video-container video[poster] {
            height: 600px;
        }
    }
}

@media only screen and (max-width: 900px) {
	.revealed-item-container {
        padding: 2rem;
    }
}