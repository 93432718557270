.revealed-items-container {
    display: flex;
	flex-direction: column;
    grid-row-start: 2;
    grid-row-end: 3;
    grid-template-columns: 100%;
    grid-template-rows: calc(100vh - 180px) 120px;
    .revealed-items-content {
        display: flex;
        flex-direction: column;
        grid-template-rows: 300px auto;
        padding-top: 2rem;
        padding-bottom: 21rem;
        &.no-items {
            grid-row-start: 1;
            grid-row-end: 3;
        }
        .revealed-items-header-container {
            display: flex;
			padding: 0 2rem;
            grid-row-start: 1;
            grid-row-end: 2;
            .revealed-items-header-content {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                width: 50rem;
                text-align: center;
                margin: 0 auto;
                h1 {
                    font-weight: bold;
                }
            }
        }
        .revealed-items-box-container {
            display: grid;
            box-sizing: border-box;
            grid-row-start: 2;
            grid-row-end: 3;
            padding: 5rem 18rem 5rem 15rem;
            &.no-items {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                h1 {
                    font-size: 32px;
                    font-weight: bold;
                }
                .reveal-items-content {
                    margin: 40px 0px 50px 0px;
                    text-align: center;
                    font-size: 1.2rem;
                    p {
                        margin: 0px;
                    }
                }
                .reveal-items-actions {
                    display: flex;
                    flex-direction: row;
                    button {
                        margin: 0px 10px;
                        width: 150px;
                    }
                }
        
            }
            .revealed-items-box-content {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 3rem;
                grid-row-gap: 3rem;
            }
        }
    }  

    .reveal-items-actions-container {
        display: grid;
        grid-row-start: 2;
        grid-row-end: 3;
	    position: fixed;
	    bottom: 0;
	    width: 100%;
	    min-height: 75px;
	    background: #fff;
        .reveal-items-actions-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-self: center;
            border-top: 1px solid #efefef;
            width: 100%;
            button {
                margin: 0px auto;
                min-width: 200px;
                font-size: 16px;
                font-family: "Gothia Serif", serif;
            }
            .view-revealed-boxes {
                color: black;
                text-decoration: underline;
                position: absolute;
                right: 30px;
            }
        }
    }
}


.randomization-loader-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
    font-size: 14px;
    text-align: center;
    .randomization-loader-video-container {
        width: 200px;
        height: 200px;
    }
    .loader-icon {
        font-size: 62px;
    }
    .transaction-hash {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        margin: 30px 0px 0px 0px;
        text-align: center;
    }
}

@media only screen and (max-width: 1400px) {
	.revealed-items-container {
		.revealed-items-content {
			.revealed-items-box-container {
				padding: 0px 10rem 5rem 8rem;
			}
		}  

	}
}
@media only screen and (max-width: 1200px) {
	.revealed-items-container {
		.revealed-items-content {
			.revealed-items-box-container {
				padding: 0px 8rem 5rem 8rem;
			}
		}  

	}
}
@media only screen and (max-width: 900px) {
.revealed-items-container {
		.revealed-items-content {
			.revealed-items-box-container{
				padding: 0rem 0rem 5rem 0rem;
				
				&.no-items {
					.reveal-items-actions {
						margin: 0px 0px 30px 0px;
					}
				}
				.revealed-items-box-content{
				   grid-template-columns: repeat(2, 1fr);
				}
				
			}
		}
	}
}
@media only screen and (max-width: 600px) {
.revealed-items-container {
		.reveal-items-actions-container{
			.reveal-items-actions-content {
				 button {
					margin: 0px 2rem;
                 }
			}
		}
	}
}
@media (min-width: 901px) and (max-width: 1200px) {

}

@media (min-width: 1201px) and (max-width: 1600px) {

}
  