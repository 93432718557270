.transaction-hash-content {
    font-size: 12px;
    margin: 0px 0px 30px 0px;
    .transaction-address-link {
        color: black;
        .transaction-address {
            text-decoration: underline;
            margin: 0px 5px;
        }
    }
}