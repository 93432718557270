@import '~antd/dist/antd.css';

h1, h2, h3, h4, h5, h6 {
  font-family: "Gothia Serif",serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

ul {
  list-style: none;  
  margin: 0px;
  padding: 0px;    
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #000;
  text-shadow: 0 0 0.25px currentcolor;
}

.ant-tabs-tab {
  color: #666;
}

.ant-tabs-tab:hover {
  color: #000;
}

.ant-btn-primary {
  color: #fff;
  border-color: #000;
  background: #000;
  width: 200px;
}

.ant-btn-primary:hover, .ant-btn-primary:focus {
  border-color: #000;
  background: #000;
}

.ant-btn::before {
  opacity: 0.8;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #000;
  border-color: #000;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #000;
}

@media only screen and (max-width: 900px) {
  .ant-tabs-nav-list {
    display: flex;
    width: 100%;
    justify-content: center;
   
  }
  .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    font-size: 14px;
  }
}