.reveal-container {
    display: flex;
    grid-row-start: 2;
    grid-row-end: 3;
	flex-direction: column;
    .reveal-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding-top: 2rem;
        h1 {
            font-weight: bold;
            font-size: 40px;
            margin: 0px;
        }
        .loader-video-container {
            width: 300px;
            height: 300px;
        }
        .randomize-container {
            img {
                width: 160px;
            }
        }
        .error-container {
            margin: 60px 0px;
            display: flex;
            align-items: center;
            .error-icon {
                font-size: 35px;
                color: red;
                margin: 0px 15px 0px 0px;
            }
        }
        .error-actions-container {
            button {
                margin: 0px 15px;
            }
        }
        .messages-container {
            display: flex;
            flex-direction: column;
            margin: 1em 0px 1em 0px;
            font-size: 14px;
            width: 550px;
			max-width: 100%;
			padding: 0 2rem;
        }
        .counter-container {
            font-size: 18px;
            font-weight: bold;  
            .counter-state {
                li {
                    margin: 0px 0px 3px 0px;
                    &.active {
                        opacity: 1;

                    }
                    .check-icon {
                        color: green;
                        margin: 0px 5px 0px 0px;
                    }
                    opacity: 0.5;
                }
            }
            .counter-timer {
                margin-top: 1em;
            }
        }
    
        .stepper-container {
            display: flex;
            flex-direction: column;
            margin: 0px 0px 30px 0px;
            font-size: 18px;
        }
        .loader-icon {
            font-size: 62px;
        }
        .transaction-hash-content {
            font-size: 12px;
            margin: 0px 0px 60px 0px;
            .transaction-address-link {
                color: black;
                .transaction-address {
                    text-decoration: underline;
                    margin: 0px 5px;
                }
            }
        }
        .note-content {
            font-style: italic;
        }
    }
}

@media only screen and (max-width: 1700px) {
	.randomization-transaction-loader-content {
		.loader-video-container {
			width: 220px;
			height: 220px;
		}
	}
}