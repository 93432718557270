.loader-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
	padding-top: 2rem;
    h1 {
        margin: 0px 0px 30px 0px;
        font-weight: bold;
        font-size: 40px;
    }
    .loader-video-container {
        width: 300px;
        height: 300px;
    }
    .messages-container {
        display: flex;
        flex-direction: column;
        margin: 30px 0px 30px 0px;
        font-size: 14px;
    }
    .stepper-container {
        display: flex;
        flex-direction: column;
        margin: 0px 0px 0px 0px;
        font-size: 14px;
    }
    .loader-icon {
        font-size: 62px;
    }
}