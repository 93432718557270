.box-revealed-item-container {
    margin: auto;
    position: relative;
    .revealed-video-container {
        width: 300px;
        height: 300px;
        transition: 250ms;
        overflow: hidden;
        border: 2px solid #efefef;
        video[poster] {
            transition: 250ms;
        }
    }
    .reveled-item-box-id {
        text-align: center;
        line-height: 42px;
    }
    &.active {
        .reveal-video-container {
            border: 2px solid #333;
        }
    }
    &:not(.active):hover {
        .reveal-video-container {
            border: 2px solid #efefef;
        }
    }
}

@media only screen and (max-width: 1200px) {
	.box-revealed-item-container {
		.revealed-video-container {
		    width: 100%;
			height: auto;
		}
	}
}