.revelead-boxes-container {
    display: flex;
    grid-row-start: 2;
    grid-row-end: 3;
	flex-direction: column;
    grid-template-columns: 100%;
    grid-template-rows: 80px calc(100vh - 220px) 80px;
    .revelead-boxes-content {
        display: flex;
		flex-direction: column;
        grid-row-start: 2;
        grid-row-end: 3;
        grid-template-rows: 180px auto;
		padding-top: 2rem;
        &.no-items {
            grid-row-start: 1;
            grid-row-end: 3;
        }
        .revelead-boxes-header-container {
            display: grid;
            grid-row-start: 1;
            grid-row-end: 2;
            .revelead-boxes-header-content {
                display: flex;
                align-items: center;
                justify-content: center;
                flex-direction: column;
                h1 {
                    font-weight: bold;
                }
            }
        }
        .revelead-boxes-items-container {
            display: grid;
            box-sizing: border-box;
            grid-row-start: 3;
            grid-row-end: 4;
            padding: 0px 15rem 10rem 15rem;
            &.no-items {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                h1 {
                    font-size: 32px;
                }
                .revelead-boxes-items-content {
                    margin: 40px 0px 50px 0px;
                    text-align: center;
                    font-size: 1.2rem;
                    p {
                        margin: 0px;
                    }
                }
                .revelead-boxes-items-actions {
                    display: flex;
                    flex-direction: row;
                    button {
                        margin: 0px 10px;
                        width: 150px;
                    }
                }
            }
            .revelead-boxes-items-content {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                gap: 3rem;
                grid-row-gap: 3rem;
				padding-top: 2rem;
            }
        }
    }  

    .revelead-boxes-items-actions-container {
        background-color: white;
        z-index: 10;
        position: fixed;
        bottom: 0px;
        width: 100%;
        height: 80px;
        .revelead-boxes-items-actions-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-self: center;
            border-top: 1px solid #efefef;
            width: 100%;
            height: 100%;
            button {
                margin: 0px auto;
                min-width: 200px;
                font-size: 16px;
                font-family: "Gothia Serif", serif;
            }
            .view-glass-boxes {
                color: black;
                text-decoration: underline;
                position: absolute;
                right: 30px;
            }
        }
    }
}

.randomization-loader-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 400px;
    font-size: 14px;
    text-align: center;
    .randomization-loader-video-container {
        width: 200px;
        height: 200px;
    }
    .loader-icon {
        font-size: 62px;
    }
    .transaction-hash {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        margin: 30px 0px 0px 0px;
        text-align: center;
    }
}

.ant-modal.avg-gas-modal-container {
    width: 550px !important;
    .avg-gas-modal-content {
        padding: 30px;
        h1 {
            font-weight: bold;
        }
        &.step-one {
            .important {
                font-weight: bold;
            }
            a {
                color: black;
                text-decoration: underline;
            }
            ul {
                list-style: disc;
                padding: 0px 0px 0px 13px;
                li {
                    margin: 25px 0px 25px 0px;
                }
            }
            .acknowledge-container {
                margin: 30px auto;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            
        }
        
        &.step-two {
            .important {
                font-weight: bold;
            }
            ul {
                list-style: disc;
                padding: 0px 0px 0px 13px;
                li {
                    margin: 25px 0px 25px 0px;
                }
            }
            
        }

        &.step-three {
            .avg-gas-price-container {
                border: 1px solid #000;
                border-radius: 5px;
                width: 160px;
                height: 140px;
                display: flex;
                align-items: center;
                justify-content: space-evenly;
                flex-direction: column;
                margin: 25px auto;
                .title {
                    font-weight: bold;
                }
                .avg-gas-price-value {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    line-height: 28px;
                    
                    .value {
                        font-size: 36px;
                    }
                    .units {
                        font-size: 18px;
                    }
                }
            }  
            .avg-gas-modal-actions-container {
                justify-content: center;
            }
        }
        .avg-gas-modal-actions-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 30px 0px 0px 0px;
            button {
                font-size: 14px;
            }
        }  
    }
}

@media only screen and (max-width: 1400px) {
    .revelead-boxes-container {
        .revelead-boxes-content {
            .revelead-boxes-items-container {
                display: grid;
                box-sizing: border-box;
                grid-row-start: 3;
                grid-row-end: 4;
                padding: 0px 10rem 10rem 10rem;
            }
            
        }  
        .revelead-boxes-items-actions-container .revelead-boxes-items-actions-content button {
            margin: 0px 15px;
        }
    }  
}

@media only screen and (max-width: 1200px) {
    .revelead-boxes-container {
        .revelead-boxes-content {
            .revelead-boxes-items-container {
                display: grid;
                box-sizing: border-box;
                grid-row-start: 3;
                grid-row-end: 4;
                padding: 0px 8rem 8rem 8rem;
            }
        }  
    }
}

@media only screen and (max-width: 900px) {
	.revelead-boxes-container {
		padding: 1rem;
		.revelead-boxes-content {
			.revelead-boxes-items-container {
				padding: 0rem;
				&.no-items {
					.reveal-items-actions {
						margin: 0px 0px 30px 0px;
					}
				}
				.revelead-boxes-items-content {
                    display: grid;
                    grid-template-columns: repeat(2, 1fr);
                    gap: 3rem;
                    grid-row-gap: 3rem;
				}
			}
		}
	}	
	.revelead-boxes-container{
		.revelead-boxes-content{
			.revelead-boxes-items-container{
				padding: 0rem 0rem 5rem 0rem;
				.revelead-boxes-items-content{
					grid-template-columns: repeat(2, 1fr);
				}
			}
		}
	}	
}

@media (min-width: 901px) and (max-width: 1200px) {

}

@media (min-width: 1201px) and (max-width: 1600px) {

}
  