.no-items-container {
    display: grid;
    box-sizing: border-box;
    grid-row-start: 1;
    grid-row-end: 3;
    .no-items-content {
        display: flex;
		padding: 0 2rem;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        grid-row-start: 1;
        grid-row-end: 3;
        .no-items-video-container {
            height: 400px;
        }
        h1 {
            font-size: 32px;
        }
        .no-items-content {
            margin: 40px 0px 50px 0px;
            text-align: center;
            font-size: 1.2rem;
            p {
                margin: 0px;
            }
        }
        .no-items-actions {
            display: flex;
            flex-direction: row;
            button {
                margin: 0px 10px;
                width: 150px;
            }
        }
    }
    .reveal-items-actions-container {
        background-color: white;
        z-index: 10;
        position: fixed;
        left: 0px;
        bottom: 0px;
        width: 100%;
        height: 80px;
        .reveal-items-actions-content {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-self: center;
            border-top: 1px solid #efefef;
            width: 100%;
            button {
                margin: 0px auto;
                min-width: 200px;
                font-size: 16px;
                font-family: "Gothia Serif", serif;
            }
            .view-revealed-boxes {
                color: black;
                text-decoration: underline;
                position: absolute;
                right: 30px;
            }
        }
    }
}

@media only screen and (max-width: 1200px) {
    .no-items-container {
        .reveal-items-actions-container .reveal-items-actions-content button {
            margin: 0px 15px;
        }
    }
}