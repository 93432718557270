.no-items-video-container {
    width: 100%;
    height: 100%;
    .video-container {
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        display: flex;
        &.active {
            video {
                display: inherit;
            }
            .loader-icon {
                display: none;
            }
        }
        .loader-icon {
            font-size: 2.5rem;
            position: absolute;
            z-index: 1;
        }
        video[poster]{
            height:100%;
            width:100%;
        }
    }
}
@media only screen and (max-width: 1700px) {
	.reveal-content{
		.no-items-video-container {
			width: 300px;
			height: 300px;
		}
	}
}
@media only screen and (max-width: 900px) {
	.no-items-video-container {
        .video-container {
            display: flex;
            width: 100%;
            height: 100%;
            align-items: center;
            justify-content: center;
            margin: 0 auto;
        }
    }
}