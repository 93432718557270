.home-container {
    display: grid;
    grid-row-start: 2;
    grid-row-end: 5;
    .home-content {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        margin: 0 auto;
        padding: 0 5rem;
        .home-video-container {
            width: 35%;
            margin: 0px 5rem 0px 0px;
            position: relative;
            bottom: 50px;
        }
        .home-content-container {
            width: 45%;
            h1 {
                margin: 0px 0px 30px 0px;
                font-weight: bold;
                font-size: 40px;
            }
            h3 {
                margin: 30px 0px 15px 0px;
                font-size: 24px;
                font-weight: bold;
            }
            ol {
                padding: 0px 15px;
            }
        }
    }
    .home-actions {
        margin: 50px 0px;
    }
}

@media only screen and (max-width: 1200px) {
.home-container {
     .home-content {
        padding: 0 2rem;
        .home-video-container {
            width: 30%;
            margin: 0px 5rem 0px 0px;
            position: relative;
            bottom: 50px;
        }
        .home-content-container {
            width: 60%;
        }
    }
}

}

@media only screen and (max-width: 920px) {
.home-container {
     .home-content {
        padding: 0 2rem;
		flex-direction: column;
        .home-video-container {
            width: 50%;
            margin: 0px auto;
            position: relative;
            bottom: 0px;
        }
        .home-content-container {
            width: 100%;
            h1 {
                font-size: 34px;
            }
        }
    }
}

}