.reveal-items-actions-container {
    display: grid;
    grid-row-start: 4;
    grid-row-end: 5;
    .reveal-items-actions-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-self: center;
        button {
            margin: 0px 25px;
            min-width: 200px;
            font-size: 16px;
            font-family: "Gothia Serif", serif;
        }
    }
}