.header-container {
    background-color: #fff;
    border-bottom: 1px solid #E6E6E6;
    width: 100%;
    height: 60px;
    position: fixed;
    top: 0px;
    z-index: 10;
    .header-content {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        height: 100%;
        padding: 0px 70px;
        ul {
            li {
                margin: 0px 10px;
                float: left;
                a {
                    color: #000;
                }
            }
        }
        .connect, .connected {
            width: unset;
        }
    }
}

@media only screen and (max-width: 600px) {
.header-container {
    .header-content {
        padding: 0px 1.5rem;
        ul {
            li {
				display: none;
            }
        }
    }
}
}