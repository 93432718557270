.stepper-router-container {
    position: relative;
    .stepper-router-content {
        display: flex;
        align-items: center;
        justify-self: center;
        width: 25rem;
        margin: 0 auto;
        height: 5rem;
        z-index: 2;
        position: relative;
        .steps {
            display: flex;
            align-items: center;
            width: 330px;
            margin: 0 auto;
            .circle {
                position: relative;
                background-color: #A9AEB8;
                border-radius: 50%;
                width: 21px;
                height: 21px;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                border: 2px solid #A9AEB8;
                &.active {
                    background-color: #fff;
                    border: 2px solid #000;
                }
                &.done {
                    background-color: #000;
                    border: 2px solid #000;
                }
                .step-desc {
                    position: absolute;
                    top: 40px;
                    font-size: 12px;
                }
            }
            .line {
                height: 2px;
                background-color: #a9aeb8;
                width: 40%;
                &.done {
                    background-color: #000;
                }
            }
        }
    }
    .stepper-shadow {
        position: absolute;
        height: 1rem;
        background-color: white;
        width: 100%;
        box-shadow: 0px 9px 7px #fff;
        z-index: 1;
        bottom: -14px;
    }
}